<template>
  <div class="box-head-info d-flex align-items-center justify-content-end border-bottom pb-2">
    <div class="d-flex align-items-center justify-content-between" :class="episodeDisplay ? 'd-block' : 'd-none'"
      style="height: 35px;">
      <div class="d-flex align-items-center gap-2">
        <label class="m-0 cursor-default fw-bold">Episodio:</label>
        <Inplace :closable="true">
          <template #closeicon>
            <i class="pi pi-check"></i>
          </template>
          <template #display>
            {{ episodeDisplay }}
          </template>
          <template #content>
            <InputText v-model="episodeDisplay" autofocus :onChange="handleEpisodeDisplayChange"
              style="min-width: 263px" />
          </template>
        </Inplace>
      </div>
      <Button v-tooltip.top="'Copiar episodio'" icon="bi bi-clipboard2-check" rounded class="ms-3 btn btn-icon btn-copy"
        @click="copyToClipboard"></Button>
    </div>
  </div>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { useGlobalStore } from '@/stores'
import services from '@/helpers/services';
import { ref } from 'vue';

const toast = useToast();
const store = useGlobalStore();

const episodeDisplay = ref("")

const copyToClipboard = () => navigator.clipboard.writeText(episodeDisplay.value)

const handleEpisodeDisplayChange = () => {
  if (!episodeDisplay.value) {
    store.setEpisodeName("")
    episodeDisplay.value = store.episode.episodeId;
  } else {
    store.setEpisodeName(episodeDisplay.value)
  }

  const { error, ...episode } = services.updateEpisode({
    episodeId: store.episode.episodeId,
    episodeName: store.episode.episodeName
  })

  if (error) {
    toast.add({ severity: 'error', summary: 'Error', detail: error });
  }

  store.setLastSavedEpisode(episode);
}

store.$onAction(({ name, after }) => {
  after(() => {
    episodeDisplay.value = store.episode.episodeName || store.episode.episodeId;

    if (name === 'restart') {
      episodeDisplay.value = ""
    }

    if (name === "loadEpisode") {
      episodeDisplay.value = store.episode.episodeName || store.episode.episodeId
    }
  })
})

</script>

<style scoped>
.box-head-info {
  height: 40px;
}
</style>
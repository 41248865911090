<template>
  <Dialog v-model:visible="store.errorDialogOpen" modal dismissableMask :draggable="false" :style="{ width: '600px' }">
    <template #header>
      <div class="d-flex align-items-center gap-2 cursor-default">
        <div class="wrap-icon error">
          <i class="bi bi-info-lg mr-3" style="font-size: 1.65rem"></i>
        </div>
        <div class="p-dialog-title fs-4">Error</div>
      </div>
    </template>
    <div class="d-flex align-items-center justify-content-center py-3">
      <div class="fs-5 text-center cursor-default">¡Ha ocurrido un error!</div>
    </div>
    <div class="text-center">
      <Button link @click="handleSeeMoreClick">{{ seeMore ? 'Ver menos' : 'Ver detalles técnicos' }}</Button>
    </div>
    <div v-if="seeMore" class="my-4" :style="{ 'padding-inline': '1.25rem' }">
      <pre>{{ JSON.stringify(store.errorDialogError, undefined, 2) }}</pre>
    </div>
    <template #footer>
      <div class="d-flex align-items-center justify-content-center">
        <Button class="btn btn-primary" label="Aceptar" rounded @click="handleAcceptClick"></Button>
      </div>
    </template>
  </Dialog>
</template>

<script setup>
import { ref } from 'vue'
import { useGlobalStore } from '@/stores'

const store = useGlobalStore()

const seeMore = ref(false)

const handleAcceptClick = () => {
  store.toggleErrorDialog(false)
  store.setErrorDialogError(null)
}

const handleSeeMoreClick = () => {
  seeMore.value = !seeMore.value
}

</script>

<style scoped>
pre {
  padding: 1rem;
  margin: 0;
  border-radius: 0.5rem;
  color: var(--bs-body-title);
  background: var(--bs-accordion-header-link-bg);
}
</style>
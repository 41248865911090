<template>
  <div class="page d-flex flex-column">
    <div class="page-bg-image"></div>
    <div class="wrapper d-flex flex-column justify-content-between">
      <Header></Header>
      <main class="wrap-main d-flex align-items-start container-xxl">
        <div class="content flex-row-fluid">
          <div class="card">
            <TopNav />
            <Loader />
            <FormWizard ref="form" color="var(--bs-primary)" error-color="var(--guo-color-r)" step-size="xs"
              v-if="!store.loadingInitialResources">
              <TabContent title="Elegir canal" icon="bi bi-diagram-3" :beforeChange="beforeChannelsTabChange">
                <ChannelsTab />
              </TabContent>
              <TabContent title="Tipo de Evento" icon="bi bi-ui-checks-grid" :beforeChange="beforeEventsTabChange">
                <EventsTab />
              </TabContent>
              <!-- only render this if theres more than client loaded -->
              <TabContent :title="clientsTabTitle" icon="bi bi-card-list" :beforeChange="beforeClientsTabChange"
                v-if="!store.hasSingleClient">
                <ClientsTab />
              </TabContent>
              <TabContent title="Contenido" icon="bi bi-postcard" :beforeChange="beforeContentsTabChange">
                <ContentsTab />
              </TabContent>
              <TabContent title="Programación" icon="bi bi-calendar2-check">
                <ScheduleTab />
              </TabContent>
              <TabContent title="¡Listo!" icon="bi bi-hand-thumbs-up">
                <ReadyTab />
              </TabContent>
              <template v-slot:footer="props">
                <div class="wizard-footer-left">
                  <Button v-if="props.activeTabIndex > 0" @click="props.prevTab()" severity="secondary" rounded
                    outlined>Atr&aacute;s</Button>
                </div>
                <div class="wizard-footer-right">
                  <Button v-if="!props.isLastStep" @click="props.nextTab()" rounded>Continuar</Button>
                  <Button v-else @click="handleFormComplete" rounded :disabled="store.loading || store.episodeCreated">
                    {{ store.episodeCreated ? "Publicado" : "Publicar" }}</Button>
                </div>
              </template>
            </FormWizard>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </div>
  </div>

  <template v-if="store.scheduleSidebarOpen">
    <ScheduleSidebar />
  </template>
  <SuccessDialog />
  <ErrorDialog />
  <SearchDialog />
</template>

<script setup>
import { computed, onMounted } from 'vue'
import Header from "@/components/header/Header.vue";
import Footer from "@/components/footer/Footer.vue";
import TopNav from "@/components/topnav/TopNav.vue";
import Loader from '@/components/loader/Loader.vue';
import ChannelsTab from "@/components/formTabs/channelsTab/ChannelsTab.vue";
import EventsTab from "@/components/formTabs/eventsTab/EventsTab.vue";
import ClientsTab from "@/components/formTabs/clientsTab/ClientsTab.vue";
import ContentsTab from "@/components/formTabs/contentsTab/ContentsTab.vue";
import ScheduleTab from "@/components/formTabs/scheduleTab/ScheduleTab.vue";
import ReadyTab from "@/components/formTabs/readyTab/ReadyTab.vue";
import ScheduleSidebar from "@/components/sidebars/ScheduleSidebar.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue";
import ErrorDialog from '@/components/dialogs/ErrorDialog.vue';
import SearchDialog from "@/components/dialogs/SearchDialog.vue";
import { form } from '@/stores/refs'
import { useGlobalStore } from '@/stores'
import { errorSwal, infoSwal } from '@/helpers/swal'
import { serializeEpisode } from '@/helpers/helpers'
import services from '@/helpers/services'

const store = useGlobalStore()

const clientsTabTitle = computed(() => {
  if (store.clientsLoadedAmount) {
    return `Listado (${store.selectedClientsAmount}/${store.clientsLoadedAmount})`
  }
  return `Listado`
})

const beforeChannelsTabChange = () => {
  const isChannelTabValid = store.episode.channels?.length;
  if (!isChannelTabValid) {
    infoSwal({ text: 'Para continuar, elige al menos un canal' })
    return false;
  }

  if (!store.isFormSaved) {
    return true;
  }

  const { error, ...episode } = services.createEpisode({
    episodeId: store.episode.episodeId,
    channels: store.episode.channels,
  })

  if (error) {
    errorSwal({ text: error })
    return false;
  }

  store.setEpisodeId(episode.episodeId);

  store.setLastSavedEpisode(episode)
  return true;

}

const beforeEventsTabChange = () => {
  // IMPORTANT
  // if the default value doesn't changes this is necessary
  // it puts the value of store.eventContentType into store.episode.eventContentType
  store.setEpisodeEventContentType(store.eventContentType)

  const isEventsTabValid = store.episode.eventType && store.episode.eventContentType;
  if (!isEventsTabValid) {
    infoSwal({ text: "Debe seleccionar un tipo de contenido" })
    return false;
  }

  if (!store.isFormSaved) {
    return true;
  }

  const { error, ...episode } = services.updateEpisode({
    episodeId: store.episode.episodeId,
    eventType: store.episode.eventType,
    eventContentType: store.episode.eventContentType,
  })

  if (error) {
    errorSwal({ text: error })
    return false;
  }

  store.setLastSavedEpisode(episode)

  return true;
}

const beforeClientsTabChange = () => {
  const isClientsTabValid = store.episode.clients?.length;
  if (!isClientsTabValid) {
    infoSwal({ text: 'Para continuar, debe seleccionar al menos un cliente' })
    return false;
  }

  if (!store.isFormSaved) {
    return true;
  }

  const { error, ...episode } = services.updateEpisode({
    episodeId: store.episode.episodeId,
    clients: store.episode.clients,
  })

  if (error) {
    errorSwal({ text: error })
    return false;
  }

  store.setLastSavedEpisode(episode)

  return true;
}

const beforeContentsTabChange = () => {
  const isContentsTabValid = store.episode.media && store.episode.mediaDescription
  if (!isContentsTabValid) {
    infoSwal({ text: 'Para continuar, debe agregar el contenido correspondiente, imagen y descripción' })
    return false;
  }
  const { error, ...episode } = services.updateEpisode({
    episodeId: store.episode.episodeId,
    media: store.episode.media,
    mediaDescription: store.episode.mediaDescription
  })

  if (!store.isFormSaved) {
    return true;
  }

  if (error) {
    errorSwal({ text: error })
    return false;
  }

  store.setLastSavedEpisode(episode)

  return true;
}

const handleFormComplete = async () => {
  store.setLoading(true)
  // apply any needed transformations to the stored episode
  const episode = serializeEpisode(store.episode)
  // send stored episode to the middleware
  const result = await services.postEpisode({ episode })

  store.setLoading(false)
  if (result.error) {
    console.log(result);
    store.setErrorDialogError({ ...result.error, episodeId: store.episode.episodeId })
    return;
  }

  // show success dialog
  store.setEpisodeCreated(true);
  store.toggleSuccessDialog(true)
}

store.$onAction(({ name }) => {
  // Cuando se ejecute la accion "restart" del store Global, 
  // reseteamos el form al step 1
  if (name === 'restart') {
    form.value.reset()
  }
})

onMounted(() => {
  store.loadInitialResources()
})
</script>

<style scoped>
.wrap-preview {
  width: 100%;
}

.wrap-cards-list {
  width: 350px;
}

.wrap-items {
  position: relative;
  border: 0 !important;
  border-radius: 0.625rem;
  box-shadow: 0 0.1rem 1rem 0.25rem rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.item-card.selected {
  border-color: var(--guo-color-primary) !important;
  background-color: var(--guo-color-secondary);
  pointer-events: none;
}

.item-card {
  background-color: var(--bs-app-blank-bg);
  border: 2px solid transparent !important;
  border-radius: 0.625rem;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.item-card:not(.item-simple) {
  padding-left: 45px !important;
}

.wrap-items:not(.disabled) .item-card:not(.selected):hover {
  background-color: var(--bs-app-blank-bg-hover);
  border-color: rgba(var(--guo-color-primary-rgb), 0.3) !important;
}

.item-card .wrap-thumb {
  width: 60px;
}

.item-card .wrap-caption {
  width: calc(100% - 88px);
}

.input-filter-search {
  width: 220px;
}

.wrap-preview.pending-review .wrap-cards-preview {
  width: calc(100% - 350px - 1.5rem);
}

.wrap-preview.pending-review .wrap-cards-preview>div,
.wrap-preview.pending-review .wrap-cards-preview>div>div {
  width: 100%;
  height: 100%;
}

.wrap-preview.pending-review .wrap-cards-list {
  width: 350px;
}

.wrap-preview .tabview-custom {
  width: 500px;
}

.wrap-preview.pending-review .tabview-custom {
  width: 100%;
  height: 100%;
}
</style>

import { defineStore } from "pinia";
import services from "@/helpers/services";
import {
  defaultcontentAssignationMechanisms,
  defaultEventContentTypes,
  formStatuses,
  getChannelsData,
  getEventTypesData,
  getSchedulesAttributes,
  repeatedElements,
  nextExecutionsDatesToCalendarEvents,
} from "@/helpers/helpers";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";

export const useGlobalStore = defineStore("global", {
  state: () => {
    return {
      /* APP STUFF */
      // loading general, si esta en true se mostrara un loader en toda la pantalla
      loading: false,
      // si se estan cargando recursos iniciales
      // usando en Main.vue
      loadingInitialResources: false,
      // error actual, sera un objeto con toda la informacion posible
      error: null,
      // error para mostrar en el ErrorDialog
      errorDialogError: null,
      // used in ClientSidebar
      clientExpanded: null,
      // is the ClientSidebar opened
      clientSidebarOpen: false,
      // is the ScheduleSidebar opened
      scheduleSidebarOpen: false,
      // is the SuccessDialog opened
      successDialogOpen: false,
      // is the ErrorDialog opened
      errorDialogOpen: false,
      // is the SearchDialog opened
      searchDialogOpen: false,
      // the last saved episode on the localStorage
      lastSavedEpisode: {},
      // is the current episode saved to the database or localStorage
      episodeCreated: false,
      // is it loading the next schedules
      loadingNextSchedules: false,
      // schedules next dates and error, if any
      preSchedules: {
        next: [],
        error: null,
      },
      // first visible date in the calendar's current view
      calendarStartDate: null,
      // last visible date in the calendar's current view
      calendarEndDate: null,
      // default view in the calendar when the app loads
      calendarView: "day",
      // list of events to be displayed in the shcedules calendar
      calendarEvents: [],
      // list of clients loaded through the api
      clientsLoaded: [],
      // current episode
      episode: {},

      /* ACTUAL EPISODE FIELDS */
      // current episode Id
      episodeId: "",
      // current episode name
      episodeName: "",
      // is the episode saved/published
      isPublished: false,
      // list of available channels (Instagram, Facebook, etc...)
      channels: new Set([]),
      // list of content types (Post, Photo, Document, etc...)
      eventContentType: defaultEventContentTypes[0].value,
      // list of currently selected clients
      clients: [],
      // uploaded media to be posted
      media: "",
      // media description/content to be posted
      mediaDescription: "",
      //@deprecated; type of event (own, generic, etc...)
      eventType: null,
      // @deprecated; (autopilot, manual)
      contentAssignationMechanism: defaultcontentAssignationMechanisms[0].value,
      // list of current schedules
      schedules: [],
    };
  },
  getters: {
    channelsData() {
      if (!this.episode.channels) {
        return [];
      }
      const channelsData = getChannelsData(this.episode.channels);
      return channelsData;
    },
    eventTypesData() {
      const allSelectedChannelEvents = [];
      let eventTypesNames = [];

      this.channelsData.forEach((ch) => {
        allSelectedChannelEvents.push(ch.eventTypes);
      });
      const flatted = allSelectedChannelEvents.flat();

      if (this.channelsData.length > 1) {
        eventTypesNames = repeatedElements(flatted, this.channelsData.length);
      } else {
        eventTypesNames = flatted;
      }

      if (!eventTypesNames.length) {
        return [];
      }

      return getEventTypesData(eventTypesNames);
    },
    formStatus() {
      if (isEmpty(this.lastSavedEpisode) && isEmpty(this.episode)) {
        return formStatuses.new;
      }

      delete this.lastSavedEpisode.isPublished;
      const areEqual = isEqual(this.lastSavedEpisode, this.episode);
      return areEqual ? formStatuses.saved : formStatuses.drafted;
    },
    isFormSaved() {
      return this.formStatus.value !== formStatuses.saved.value;
    },
    hasSingleClient() {
      return this.clientsLoaded?.length === 1;
    },
    clientsLoadedAmount() {
      return this.clientsLoaded?.length || 0;
    },
    selectedClientsAmount() {
      return this.clients?.length || 0;
    },
  },
  actions: {
    restart() {
      // Resets the state in this store
      this.$reset();
      this.loadInitialResources();
    },
    _conditionallySetValue(key, value) {
      if (value) {
        this[key] = value;
        this.episode[key] = value;
      }
    },
    setLoading(loading) {
      this.loading = loading;
    },
    setClientExpanded(client) {
      this.clientExpanded = client || null;
    },
    toggleClientSidebar(newState) {
      this.clientSidebarOpen = newState;
    },
    toggleScheduleSidebar(newState) {
      this.scheduleSidebarOpen = newState;
    },
    toggleSuccessDialog(newState) {
      this.successDialogOpen = newState;
    },
    toggleErrorDialog(newState) {
      this.errorDialogOpen = newState;
    },
    toggleSearchDialog(newState) {
      this.searchDialogOpen = newState;
    },
    setEpisodeId(newId) {
      this.episodeId = newId;
      this.episode.episodeId = newId;
    },
    setEpisodeName(newEpisodeName) {
      this.episodeName = newEpisodeName;
      this.episode.episodeName = newEpisodeName;
    },
    setIsPublished(newIsPublished) {
      this.isPublished = newIsPublished;
      this.episode.isPublished = newIsPublished;
    },
    setChannels(newChannel) {
      if (this.channels.has(newChannel)) {
        this.channels.delete(newChannel);
      } else {
        this.channels.add(newChannel);
      }

      this.episode.channels = [...this.channels];
    },
    setEpisodeEventContentType(newEventContentType) {
      this.episode.eventContentType = newEventContentType;
    },
    setEventType(eventType) {
      this.eventType = eventType;
      this.episode.eventType = eventType;
    },
    addClients(newClients) {
      // if is a list of clients, push them all
      if (Array.isArray(newClients)) {
        this.clients.push(...newClients);
        // if its just 1 client, push that one
      } else {
        this.clients.push(newClients);
      }

      this.syncEpisodeClients();
    },
    removeClient(oldClient) {
      const index = this.clients.findIndex(
        (client) => client.Cliente_id === oldClient.Cliente_id
      );

      if (index !== -1) {
        this.clients.splice(index, 1);
      }

      this.syncEpisodeClients();
    },
    setMediaDescription(newMediaDescription) {
      this.mediaDescription = newMediaDescription;
      this.episode.mediaDescription = newMediaDescription;
    },
    setMedia(newMedia) {
      this.media = newMedia;
      this.episode.media = newMedia;
    },
    setMediaType(newMediaType) {
      this.mediaType = newMediaType;
      this.episode.mediaType = newMediaType;
    },
    setMediaName(newMediaName) {
      this.mediaName = newMediaName;
      this.episode.mediaName = newMediaName;
    },
    syncEpisodeClients() {
      this.episode.clients = this.clients;
    },
    addEpisodeClients(clients) {
      this.episode.clients = clients;
    },
    removeAllEpisodeClients() {
      this.episode.clients = [];
    },
    setEpisodeContentAssignationMechanism(mewcontentAssignationMechanism) {
      this.episode.contentAssignationMechanism = mewcontentAssignationMechanism;
    },
    addEpisodeSchedule(newScheduleEventId) {
      this.schedules[0] = newScheduleEventId;
      this.episode.schedules = this.schedules;
    },
    setError(error) {
      this.error = error;
    },
    setErrorDialogError(error) {
      this.errorDialogError = error;
      this.errorDialogOpen = true;
    },
    async loadInitialResources() {
      this.loading = true;
      this.loadingInitialResources = true;

      const clients = await services.getClientsEntities();

      if (clients.error) {
        this.loading = false;
        this.loadingInitialResources = false;

        this.setError({
          severity: "error",
          detail: clients.error,
          summary: "Error al cargar clientes",
        });
        return;
      }

      this.clientsLoaded = clients;

      /* 
        if only 1 client was returned by the api,
        load it into the store and add it to the episode since there won't be an UI option to do it.
        i.e. the clients table won't be rendered
      */
      if (this.hasSingleClient) {
        this.addClients(clients);
      }

      this.loading = false;
      this.loadingInitialResources = false;
    },
    loadEpisode(newEpisode) {
      // All these are saved the same both in store[key] and store.episode[key]
      this._conditionallySetValue("episodeId", newEpisode.episodeId);
      this._conditionallySetValue("episodeName", newEpisode.episodeName);
      this._conditionallySetValue(
        "eventContentType",
        newEpisode.eventContentType
      );
      this._conditionallySetValue("clients", newEpisode.clients);
      this._conditionallySetValue("media", newEpisode.media);
      this._conditionallySetValue("mediaType", newEpisode.mediaType);
      this._conditionallySetValue("mediaName", newEpisode.mediaName);
      this._conditionallySetValue(
        "mediaDescription",
        newEpisode.mediaDescription
      );
      this._conditionallySetValue("eventType", newEpisode.eventType);
      this._conditionallySetValue(
        "contentAssignationMechanism",
        newEpisode.contentAssignationMechanism
      );
      this._conditionallySetValue("schedules", newEpisode.schedules);

      // Channels are different tho
      if (newEpisode.channels) {
        this.channels = new Set(newEpisode.channels);
        this.episode.channels = newEpisode.channels;
      }

      this.setLastSavedEpisode(this.episode);
      this.fetchScheduleEvents();
    },
    setLastSavedEpisode(newLastSavedEpisode) {
      this.lastSavedEpisode = newLastSavedEpisode;
    },
    setEpisodeCreated(newEpisodeCreated) {
      this.episodeCreated = newEpisodeCreated;
    },
    async fetchNextSchedules({ schedule }) {
      const attributes = getSchedulesAttributes({ schedule });
      const result = await services.getNextSchedules({ attributes, amount: 5 });
      if (result.error) {
        this.preSchedules.error = result.error;
        this.preSchedules.next = [];
      } else {
        this.preSchedules.next = result.result;
        this.preSchedules.error = null;
      }
      this.loadingNextSchedules = false;
    },
    syncCalendarViewDetails({ view, startDate, endDate }) {
      this.calendarView = view;
      this.calendarStartDate = startDate;
      this.calendarEndDate = endDate;
    },
    async fetchScheduleEvents() {
      // si no hay schedule creado (i.e: se ha cargado la app pero no se ha llegado al tab de programaciones)
      if (!this.episode?.schedules?.[0]) {
        return;
      }
      // resetear porque si no se muestran eventos fuera de su lugar
      // por un aparente bug del calendario
      this.calendarEvents = [];

      const nextDates = await services.getExecutionsById({
        view: this.calendarView,
        startDate: this.calendarStartDate,
        endDate: this.calendarEndDate,
        scheduleId: this.episode?.schedules?.[0],
      });
      this.calendarEvents = nextExecutionsDatesToCalendarEvents(nextDates);
    },
  },
});
